.ad-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

.ad-table td,
.ad-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.ad-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.ad-table tr:hover {
  background-color: #ddd;
}

.ad-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #16302b;
  color: white;
}

.priceplan:hover {
  transform: scale(1.1);
  border: 1px solid #c4c4c4;
  transition: transform 0.2s;
}
